<template>
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0" cols="auto">
        <span class="chart_title">{{
          statistic_title_name == null ? statistic_name : statistic_title_name
        }}</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="ma-0 pa-0" cols="auto">
        <v-btn icon v-if="link">
          <v-icon color="secondary" @click="open_further_details"
            >mdi-magnify-expand</v-icon
          >
        </v-btn>
      </v-col>
      <v-col cols="12" style="height: 300px">
        <v-chart :id="chart_id" class="chart" :option="option" autoresize></v-chart>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import VChart, { THEME_KEY } from "vue-echarts";
  import "echarts";
  import { use } from "echarts/core";
  import { CanvasRenderer } from "echarts/renderers";
  import { TitleComponent, TooltipComponent, LegendComponent } from "echarts/components";
  import { COLOR_PALETTE } from "./chart_constants.js";
  
  use([CanvasRenderer, TitleComponent, TooltipComponent, LegendComponent]);
  export default {
    name: "TimeSerieChart",
    props: ["statistic_title_name", "value", "link", "chart_id"],
    provide: {
      [THEME_KEY]: "light",
    },
    components: {
      VChart,
    },
    setup(props) {
      let dataset = props.value;
  
      let data_category = [];
      let data_series = [];
      let data = [];
      let series = [];
      if (dataset != null) {
        data = Array.from(Array(dataset[0].length - 1), () => []);
        data_series = dataset[0];
        data_series.shift();
  
        for (let i = 1; i < dataset.length; i++) {
          data_category.push(dataset[i][0]);
  
          for (let j = 1; j < dataset[i].length; j++) {
            data[j - 1].push(dataset[i][j]);
          }
        }
  
        for (let idx = 0; idx < data.length; idx++) {
          series.push({
            name: data_series[idx],
            type: "line",
            stack: "Total",
            data: data[idx],
            emphasis: {
              focus: "series",
            },
          });
        }
      }
  
      const option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: data_series,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: data_category,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: series,
        color: COLOR_PALETTE,
      };
      return { option };
    },
    methods: {
      open_further_details: function () {
        if (this.link) {
          this.$router.replace(this.link);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .chart_title {
    font-family: "Gothic Bold" !important;
    font-size: 10pt !important;
    line-height: 40px;
  
    display: block;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  </style>