// import Vue from 'vue'
// import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

// Vue.use(VueRouter)

// const routes = [
//   {
//     path: '/home',
//     name: 'home',
//     component: HomeView
//   },
//   {
//     path: '/about',
//     name: 'about',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//   }
// ]

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

// export default router


import Vue from 'vue'
import Router from 'vue-router'
// import { getAuth } from 'firebase/auth'

import LoginPage from '../views/LoginView.vue';
import BasePage from '../components/BasePage.vue';

import EndpointProtection from '../components/Sections/EndpointProtection.vue';
import DeviceMonitoring from '../components/Sections/DeviceMonitoring';
import EmailMonitoring from '../components/Sections/EmailMonitoring';
import QuestionaireList from '../components/Sections/QuestionaireList.vue';
import TicketingPage from '../components/Sections/Ticketing.vue';
import vCISOPage from '../components/Sections/vCISO';
import ExportPage from '../components/Sections/Export';
import PennTesting from '@/components/Sections/PennTesting.vue';
import UserSettings from '@/components/Sections/UserSettings.vue';

import { authGuard } from '../auth/authGuard';

Vue.use(Router)

const router = new Router({
    routes : [
        {
          path: '*',
          redirect: '/login'
        },
        {
          path: '/',
          redirect: '/login'
        },
        {
          path: '/login',
          name: 'Login',
          component: LoginPage,
          meta: {
              title: 'Login',
              hideNavbar: true,
          }
        },
        {
          path: '/home',
          name: 'Home',
          component: BasePage,
            meta: {
                requiresAuth: true,
                title: 'Home',
                hideNavbar: false,
            },
          beforeEnter: authGuard
        },
        {
          path: '/ticketing',
          name: 'Ticketing',
          component: TicketingPage,
            meta: {
                requiresAuth: true,
                title: 'Ticketing',
                hideNavbar: false,
            },
            beforeEnter: authGuard
        },
        {
          path: '/endpoint_protection',
          name: 'Endpoint Protection',
          component: EndpointProtection,
            meta: {
                requiresAuth: true,
                title: 'Endpoint Protection',
                hideNavbar: false,
            },
            beforeEnter: authGuard
        },
        {
          path: '/device_monitoring',
          name: 'Device Monitoring',
          component: DeviceMonitoring,
            meta: {
                requiresAuth: true,
                title: 'Device Monitoring',
                hideNavbar: false,
            },
            beforeEnter: authGuard
        },
        {
          path: '/email_monitoring',
          name: 'Email Monitoring',
          component: EmailMonitoring,
            meta: {
                requiresAuth: true,
                title: 'Email Monitoring',
                hideNavbar: false,
            },
            beforeEnter: authGuard
        }
        ,
        {
          path: '/questionnaire',
          name: 'Questionnaire',
          component: QuestionaireList,
            meta: {
                requiresAuth: true,
                title: 'Questionnaire',
                hideNavbar: false,
            },
            beforeEnter: authGuard
        },
        {
          path: '/vCISO',
          name: 'vCISO',
          component: vCISOPage,
          meta: {
            requiresAuth: true,
            title: 'vCISO',
            hideNavbar: false,
          },
          beforeEnter: authGuard
        },
        {
          path: '/export',
          name: 'Export',
          component: ExportPage,
          meta: {
            requiresAuth: true,
            title: 'Export',
            hideNavbar: false,
          },
          beforeEnter: authGuard
        },
        {
          path: '/penn_testing',
          name: 'Penn Testing',
          component: PennTesting,
          meta: {
            requiresAuth: true,
            title: 'Penn Testing',
            hideNavbar: false,
          },
          beforeEnter: authGuard
        },
        {
          path: '/user_settings',
          name: 'User Setting',
          component: UserSettings,
          meta: {
            requiresAuth: true,
            title: 'User Settings',
            hideNavbar: false,
          },
          beforeEnter: authGuard
        }
    ]
});


export default router;
