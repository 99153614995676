<template>
    <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0" cols="auto">
            <span class="chart_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="ma-0 pa-0" cols="auto">
            <v-btn icon v-if="link">
                <v-icon color="secondary" @click="open_further_details">mdi-magnify-expand</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12" class="chart_style">
            <v-chart :id="chart_id" class="chart" :option="option" autoresize></v-chart>
        </v-col>
    </v-row>
</template>

<script>
    import VChart, { THEME_KEY } from 'vue-echarts';
    import "echarts";
    import { use } from 'echarts/core';
    import { CanvasRenderer } from 'echarts/renderers';
    import {
        TitleComponent,
        TooltipComponent,
        LegendComponent,
    } from 'echarts/components';

    use([
        CanvasRenderer,
        TitleComponent,
        TooltipComponent,
        LegendComponent,
    ]);
    export default {
        name: 'FunnelChart',
        props: [
            'statistic_title_name',
            'value',
            'link',
            'chart_id'
        ],
        components: {
            VChart
            // GChart
        },
        provide: {
            [THEME_KEY]: 'light',
        },
        setup(props) {
            let dataset = props.value;

            let data = [];
            let data_titles = [];

            if(dataset != null){
                for(let idx = 0; idx < dataset.length; idx ++){
                    if(idx > 0){
                        data.push({
                            name: dataset[idx][0],
                            value: dataset[idx][1]
                        })
                        data_titles.push(dataset[idx][0]);
                    }
                }
            }

            const option = {
                title: {
                    text: '',
                    left: 'center',
                },
                tooltip: {
                    trigger: 'item',

                },
                legend: {
                    orient: 'horizontal',
                    left: 'top',
                    data: data_titles
                },
                series: [
                    {
                        name: '',
                        type: 'funnel',
                        height: '60%',
                        data: data,
                        emphasis: {
                            itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                    },
                ],
            }
            return {option}
        },
        methods: {
            format_data: function(dataset){
                let data = [];
                let data_titles = [];
                if(dataset != null){
                    for(let idx = 0; idx < dataset.length; idx ++){
                        if(idx > 0){
                            data.push({
                                name: dataset[idx][0],
                                value: dataset[idx][1]
                            })
                            data_titles.push(dataset[idx][0]);
                        }
                    }
                }
                this.option['series'][0]['data'] = data;
                this.option['legend']['data'] = data_titles;
            },
            open_further_details: function(){
                if(this.link){
                    this.$router.replace(this.link);
                }
            }
        }
    }
</script>

<style scoped>
    .chart_title{
        font-family: "Gothic Bold" !important;
        font-size: 10pt !important;
        line-height: 40px;

        display: block;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .chart_style{
        height: 25vh;
        font-family: "Gothic" !important;
    }
</style>