export const COLOR_PALETTE = [
    "#66B85A", 
    "#FF6F61",  
    "#6A5ACD", 
    "#FFA07A",  
    "#20B2AA",  
    "#FFD700",  
    "#4682B4",  
    "#FF6347",  
    "#8A2BE2",  
    "#FF4500"  
];
