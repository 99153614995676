<template>
    <v-row class="ma-0 pa-0">
        <!-- <span>
          {{ filterable_columns }}
        </span>
      <v-col class="ma-0 pa-0" cols="auto">
          <span class="chart_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="ma-0 pa-0" cols="auto">
          <v-btn icon>
              <v-icon v-if="link" color="secondary">mdi-magnify-expand</v-icon>
          </v-btn>
      </v-col> -->
      <v-col cols="12" v-if="filterable_category != null">
        <v-row class="ma-0 pa-0" justify="space-between" align="center">
          <v-col cols="12" class="ma-0 pa-0 pl-12">
            <span class="chart_title">{{ filterable_category }}</span>
          </v-col>
          <v-col cols="1" class="pt-0">
            <v-icon @click="flip_category_left" large>mdi-chevron-left</v-icon>
          </v-col>
          <v-col cols="10" class="ma-0 pa-0" style="height: 100px;">
            <v-row class="ma-0 pa-0"  style="overflow-x: scroll; display: flex; flex-wrap: nowrap;" justify="space-around">
              <v-col cols="auto" class="ma-0 pa-0" v-for="(idx, stat) in highlight_map[filterable_category]" :key="stat">
                <statistics-table-stat :statistic_title_name="stat" :value="highlight_map[filterable_category][stat]" v-on:filter_value="filter_table" v-on:unfilter_value="unfilter_table" :filter="Object.keys(data_filters).includes(filterable_category) && data_filters[filterable_category].includes(stat)"></statistics-table-stat>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="auto" v-for="(idx, stat) in highlight_map[filterable_category]" :key="idx">
            <statistics-table-stat :statistic_title_name="stat" :value="highlight_map[filterable_category][stat]" v-on:filter_value="filter_table" v-on:unfilter_value="unfilter_table"></statistics-table-stat>
          </v-col> -->
          <v-col cols="1" class="pt-0">
            <v-icon @click="flip_category_right" large>mdi-chevron-right</v-icon>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" >
        <v-data-table :headers="header" :items="items" align="left" height="50vh" fixed-header>
              <template v-for="(h,index) in header" v-slot:[`header.${h.value}`]>
                <span class="headerContent" :key="index">{{ h.text }}</span>
              </template>
              <template v-for="col in header" v-slot:[`item.${col.value}`]="{ item }">
                <span class="tableContent" :key="col.value">{{ item[col.value] }}</span>
              </template>

              <!-- format all plain numeric columns -->
              <!-- <template v-for="numCol in headers" v-slot:[`item.${numCol.value}`]="{ item }">
                <div v-if="numericColumns.includes(numCol.value) || currencyColumns.includes(numCol.value) || percentageColumns.includes(numCol.value)" :key="numCol.value" :style="{backgroundColor: update_color(item)}" class="numericCellFlighting ma-0 pa-1 px-2">
                  <span v-if="numericColumns.includes(numCol.value)">
                    {{ item[numCol.value] ? item[numCol.value].toLocaleString("en-CA",{ minimumFractionDigits: 0, maximumFractionDigits: 0}) : "n/a" }}
                  </span>
                  <span v-else-if="currencyColumns.includes(numCol.value)">
                    {{ item[numCol.value] ? item[numCol.value].toLocaleString("en-CA",{style: "currency", currency: "CAD", minimumFractionDigits: 2, maximumFractionDigits: 2}) : "n/a" }}
                  </span>
                  <span v-else-if="percentageColumns.includes(numCol.value) && item[numCol.value] > 0" style="color:green">
                    {{ item[numCol.value] ? item[numCol.value].toLocaleString("en-CA",{style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2}) : "n/a" }}
                  </span>
                  <span v-else-if="percentageColumns.includes(numCol.value) && item[numCol.value] < 0" style="color:red">
                    {{ item[numCol.value] ? item[numCol.value].toLocaleString("en-CA",{style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2}) : "n/a" }}
                  </span>
                  <span v-else>
                    N/A
                  </span>
                </div>
                <div v-else :key="numCol.value" :style="{backgroundColor: update_color(item)}" class="stringCellFlighting ma-0 pa-1 px-2">
                  <span>
                    {{level_val(item, numCol.value)}}
                  </span>
                </div>
              </template> -->
        </v-data-table>
      </v-col>
    </v-row>
</template>

<script>
    import StatisticsTableStat from './StatisticsTableStat.vue';
    export default {
        name: 'StatisticsTable',
        props: [
            'statistic_title_name',
            'value',
            'link'
        ],
        components: {
          StatisticsTableStat
        },
        mounted(){
          if(this.value) {
            this.header = this.value['header'];
            this.items_raw = this.value['data'];
            this.items = this.value['data'];
            this.filterable_columns = this.value['filterable_columns'];
            this.pull_highlight_statistics();
          }
        },
        data: () => ({
            header: [],
            items: [],
            filterable_category: '',
            highlight_map: {},
            filterable_columns: [],
            data_filters: {}
        }),
        computed: {

        },
        watch: {
          value: function(){
            console.log(this.value)
            if(this.value.length > 0 || Object.keys(this.value).length > 0){
              this.header = this.value['header'];
              this.items_raw = this.value['data'];
              this.items = this.value['data'];
              this.filterable_columns = this.value['filterable_columns'];
              this.pull_highlight_statistics();
            }else{
              this.header = [];
              this.items_raw = [];
              this.items = [];
              this.filterable_columns = ['Loading...'];
              this.pull_highlight_statistics();
            }
          }
        },
        methods: {
          flip_category_left(){
            let cat_idx = this.filterable_columns.indexOf(this.filterable_category);
            cat_idx -= 1;
            if(cat_idx < 0){
              cat_idx = this.filterable_columns.length - 1;
            }
            this.filterable_category = this.filterable_columns[cat_idx];
          },
          flip_category_right(){
            let cat_idx = this.filterable_columns.indexOf(this.filterable_category);
            cat_idx += 1;
            if(cat_idx == this.filterable_columns.length){
              cat_idx = 0;
            }
            this.filterable_category = this.filterable_columns[cat_idx];
          },
          pull_highlight_statistics(){
            let highlight_map = {};

            for(let cat of this.filterable_columns){
              highlight_map[cat] = {};
              for(let row of this.items){

                if(Object.keys(row).includes(cat)){
                  if(Object.keys(highlight_map[cat]).includes(row[cat].toString())){
                    highlight_map[cat][row[cat]] += 1;
                  }
                  else{
                    highlight_map[cat][row[cat]] = 1;
                  }
                }
              }
            }
            this.highlight_map = highlight_map;
            this.filterable_category = this.filterable_columns[0];
          },
          unfilter_table(statistic_title){
            let new_filters = {};
            for(let filter_cat of Object.keys(this.data_filters)){
              new_filters[filter_cat] = [];
              for(let filter of this.data_filters[filter_cat]){
                if(filter == statistic_title){
                  continue;
                }else{
                  new_filters[filter_cat].push(filter);
                }
              }
            }
            this.data_filters = new_filters;
            this.filter_table(null);
          },
          filter_table(statistic_title){
            let new_data_rows = [];
            if(statistic_title != null){
              if(Object.keys(this.data_filters).includes(this.filterable_category)){
                // this.data_filters[this.filterable_category].push({'column': this.filterable_category, 'val': statistic_title})
                this.data_filters[this.filterable_category].push(statistic_title);
              }
              else{
                // this.data_filters[this.filterable_category] = [{'column': this.filterable_category, 'val': statistic_title}];
                this.data_filters[this.filterable_category] = [statistic_title];
              }
              // this.data_filters.push({'column': this.filterable_category, 'val': statistic_title})
            }
            let filtered_items = this.items_raw;
            for(let filter_cat of Object.keys(this.data_filters)){
              if(this.data_filters[filter_cat].length > 0){
                for(let row of filtered_items){
                  for(let filter of this.data_filters[filter_cat]){
                    if(row[filter_cat] == filter){
                      new_data_rows.push(row);
                    }
                  }
                }
                filtered_items = new_data_rows;
                new_data_rows = [];
                // this.items = new_data_rows;
              }
              // else{
              //   this.items = this.items_raw;
              // }
            }
            this.items = filtered_items;
          }
        }
    }
</script>

<style>
    .headerContent {
      font-family: "Gothic Bold" !important;
      font-size: 12pt;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      min-width: 80px;
      text-align: center;
      color: #FFFFFF;
    }
    .v-data-table__wrapper > table > thead > tr > th {
      background-color: #4C8D44 !important;
    }
    .tableContent {
      font-family: "Gothic" !important;
      font-size: 10pt;
      display: inline-block;
      min-width: 10px;
      max-width: 250px;
      text-align: center;
      color: #000000;
    }
    .chart_title{
        font-family: "Gothic Bold" !important;
        font-size: 15pt !important;
        line-height: 15pt;
    }
    .statistic_title{
        font-family: "Gothic Bold";
        font-size: 14pt;
    }
    .statistic_value{
        font-family: "Gothic";
        font-size: 12pt;
    }
    .list_header{
        font-family: "Gothic Bold" !important;
        font-size: 10pt !important;
    }
    .date-box-light {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 88px;
      background-color: #85D97C;
      color: white;
      /* border-radius: 50%; */
      font-size: 18px;
    }
    .date-box-dark {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 88px;
      background-color: #4C8D44;
      color: white;
      /* border-radius: 50%; */
      font-size: 18px;
    }

    .day {
      font-size: 22px;
      font-weight: bold;
    }

    .month {
      font-size: 18px;
    }
</style>