<template>
  <v-container fluid class="px-8">
    <div v-if="showAlert">
      <v-alert
        class="v-alert"
        v-model="showAlert"
        border="left"
        close-text="Close Alert"
        dismissible
        :type="alertType"
      >
        {{ alertMsg }}
      </v-alert>
    </div>

    <v-row class="ma-0 pa-0">
      <v-col cols="12">
        <v-row class="ma-0 pa-0" align="center">
          <v-col cols="auto" class="ma-0 pa-0">
            <span class="page-title">Ticketing</span>
            <a class="page-link" href="https://autotask.net/Mvc/Framework/Authentication.mvc/Authenticate" target="_blank">
              Autotask
              <v-icon color="#66B85A">mdi-open-in-new</v-icon>
            </a>
          </v-col>
          <v-spacer></v-spacer>
          <v-col v-show="$vuetify.breakpoint.mdAndUp" md="auto" class="ma-0 pa-0 px-3">
              <DateRange 
                  :start_date="start_date"
                  :end_date="end_date"
                  :min_start_date="min_start_date"
                  :max_end_date="max_end_date"
                  v-on:date_update="update_date_range"></DateRange>
          </v-col> 
          <v-col v-show="$vuetify.breakpoint.lgAndUp" lg="auto" class="ma-0 pa-0 px-3">
              <span class="last_updated_title">Data Last Updated</span><br>
              <span class="last_updated_text">{{  data_info != null ? data_info : '-' }}</span>
          </v-col>
          <v-col v-show="$vuetify.breakpoint.mdAndDown" cols="auto" class="ma-0 pa-0 px-3">
              <v-tooltip top>
                  <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-clock-outline</v-icon>
                  </template>
                  <TooltipDescription title="Data Last Updated." :desc_2="data_info != null ? data_info : '-'"></TooltipDescription>
              </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="ma-0 py-1">
          <v-chip @click="current_page='overview'" label class="data_breakdown_chip" :outlined="current_page != 'overview'" color="#66B85A">Overview</v-chip>
          <v-chip @click="current_page='details'" label class="data_breakdown_chip" :outlined="current_page != 'details'" color="#66B85A">Ticket Details</v-chip>
      </v-col>

      <v-col cols="6" v-if="current_page == 'overview'" class="ma-0 py-1" >
        <MainPageStat
          statistic_title_name="Open Tickets"
          :value="open_tickets"
        ></MainPageStat>
      </v-col>
      <v-col cols="6" v-if="current_page == 'overview'" class="ma-0 py-1" >
        <MainPageStat
          statistic_title_name="Critical/High Priority Open Tickets"
          :value="high_priority_open_tickets"
        ></MainPageStat>
      </v-col>
      <v-col cols="12" v-if="charts_config && current_page == 'overview'" class="ma-0 py-1" >
        <v-row class="ma-0 pa-0">
          <v-col cols="6">
            <BaseChart
              :statistic_title_name="charts_config['charts']['TicketsByPriority']['chart_title']"
              :value="charts_config['charts']['TicketsByPriority']['chart_value']"
              :chart_type="charts_config['charts']['TicketsByPriority']['chart_type']"
            >
            </BaseChart>
          </v-col>
          <v-col cols="6">
            <BaseChart
              :statistic_title_name="charts_config['charts']['TicketsByStatus']['chart_title']"
              :value="charts_config['charts']['TicketsByStatus']['chart_value']"
              chart_type="FunnelChart"
            >
            </BaseChart>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12"  v-else-if="current_page == 'overview'" class="ma-0 py-1" >
        <v-row class="ma-0 pa-0">
          <v-col cols="6">
              <v-skeleton-loader type="image,image" style="height: 264px; overflow-y: hidden;"></v-skeleton-loader>
          </v-col>
          <v-col cols="6">
              <v-skeleton-loader type="image,image" style="height: 264px; overflow-y: hidden;"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12"  v-if="current_page == 'details'" class="ma-0 py-1" >
        <StatisticsTable
          statistic_title_name="Open Tickets"
          :value="open_tickets_lst"
        ></StatisticsTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MainPageStat from "../StatisticComponents/MainPageStat";
import TooltipDescription from '../UtilityComponents/TooltipDescription.vue';
import DateRange from "../UtilityComponents/DateRange.vue";
import StatisticsTable from "../StatisticComponents/StatisticsTable.vue";
import BaseChart from "../StatisticComponents/Charts/BaseChart.vue";
import api_caller from "@/javascript/chart_config_retrieval.js";
import { ALERT_GENERIC_MESSAGE, ALERT_TYPE_ERROR } from "@/constants/constants.js";


export default {
  name: "TicketingPage",
  props: [
    'start_date',
    'end_date'
  ],
  data: () => ({
    alertMsg: null,
    alertType: null,
    showAlert: false,
    ticketing_raw_data: null,
    open_tickets_lst: [],
    old_tickets: [],
    open_tickets: null,
    open_tickets_by_priority: [],
    high_priority_open_tickets: null,
    charts_config: null,
    echart_option: null,

    data_info: null,

    current_page: 'overview',

    menu: false,
    
    date_range: [],
    min_start_date: '2024-01-01',
    max_end_date: '2024-12-31',
    // start_date: '2024-01-01',
    // end_date: '2024-01-01'
  }),
  components: {
    StatisticsTable,
    MainPageStat,
    BaseChart,
    TooltipDescription,
    DateRange
  },
  mounted() {
    this.date_range = [this.start_date, this.end_date];
    this.getChartConfig();
  },
  methods: {
    update_date_range: function(date_range){
        this.date_range = date_range;
        this.$emit('system_date_update', {'start': date_range[0], 'end': date_range[1]});
        this.getChartConfig();
    },
    getChartConfig: async function () {
      this.charts_config = null;
      this.open_tickets = null;
      this.open_tickets_lst = [];
      this.high_priority_open_tickets = null;
      const token = await this.$auth.getTokenSilently();
      let charts_config = await api_caller
      .get_ticketing_charts_config(token, this.date_range[0], this.date_range[1])
        .then((resp) => {
          if (resp["success"]) {
            return resp["data"];
          } else {
            return false;
          }
        })
        .catch((e) => {
          console.log(e);
          return false;
        });

      if (charts_config) {
        this.charts_config = charts_config;
        this.open_tickets_lst = this.charts_config['data']['OpenTicketsLst']['content']
        this.open_tickets = this.charts_config['data']['OpenTickets']['stat_value']
        this.high_priority_open_tickets = this.charts_config['data']['HighPriorityTickets']['stat_value'];
        this.data_info = charts_config['data_info']['data_last_updated'];


        // this.echartSetup()

      } else {
        this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
      }
    },
    echartSetup: function() {

      let rawChartData = this.charts_config['charts']['TicketsByPriority']['chart_value']

      let echartData = []
      for(let i = 1; i<rawChartData.length; i++) {
        echartData.push({name: rawChartData[i][0], value: rawChartData[i][1] })
      }

      this.echart_option = {}
      this.echart_option["pieChart"] = {
        title: {
          text: 'Pie Chart',
          left: 'center',
        },
        series: [
          {
            name: 'Pie Chart',
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            data: echartData,
            maxSize: '100%',
            sort: 'descending',
            gap: 2,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
      this.echart_option["funnelChart"] = {
        title: {
          text: 'Funnel Chart',
          left: 'center',
        },
        series: [
          {
            name: 'Funnel Chart',
            type: 'funnel',
            width: '80%',
            maxSize: '80%',
            data: echartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
    },
    triggerAlert: function (type, message) {
      this.alertType = type ? type : "info";
      this.alertMsg = message;
      this.showAlert = true;
    }
  },
};
</script>

<style scoped>
    .last_updated_title{
        font-family: "Gothic Bold" !important;
        font-size: 0.8em !important;
    }
    .last_updated_text{
        font-family: "Gothic" !important;
        font-size: 0.85em !important;
    }
    .page-title {
      font-family: "Gothic Bold" !important;
      font-size: 25pt !important;
    }
    .page-link {
      font-family: "Gothic" !important;
      font-size: 15pt !important;
      margin-left: 10px;
      color: #66b85a;
    }
    .data_breakdown_chip {
        font-family: "Gothic Bold" !important;
        /* color: black !important; */
        font-weight: 600;
        width: 175px;
        justify-content: center;
        margin-left: 1px;
        margin-right: 1px;
    }
    .theme--light.v-chip {
        color: white;
    }

    .echart {
      height: 500px;
    }
</style>
