import axios from 'axios'

// const api_endpoint = 'http://127.0.0.1:5001'
// const api_endpoint = 'http://38.117.70.27:80'
const api_endpoint = 'https://csp.api.docmasweetspot.ca'


function get_home_charts_config(token, start_date, end_date){
    let api_url = api_endpoint+'/home/get_home_charts_config';
    return axios_post_call(api_url,{'test': 'test', 'start_date':start_date, 'end_date':end_date},token);
}

function get_ticketing_charts_config(token, start_date, end_date){
    let api_url = api_endpoint+'/home/get_ticketing_charts_config';
    return axios_post_call(api_url,{'test': 'test', 'start_date':start_date, 'end_date':end_date},token);
}

function get_endpoint_protection_charts_config(token, start_date, end_date){
    let api_url = api_endpoint+'/home/get_endpoint_protection_charts_config';
    return axios_post_call(api_url,{'test': 'test', 'start_date':start_date, 'end_date':end_date},token);
}

function get_device_monitoring_charts_config(token, start_date, end_date){
    let api_url = api_endpoint+'/home/get_device_monitoring_charts_config';
    return axios_post_call(api_url,{'test': 'test', 'start_date':start_date, 'end_date':end_date},token);
}

function get_email_monitoring_charts_config(token, start_date, end_date){
    let api_url = api_endpoint+'/home/get_email_monitoring_charts_config';
    return axios_post_call(api_url,{'test': 'test', 'start_date':start_date, 'end_date':end_date},token);
}

function get_penn_charts_config(token, start_date, end_date){
    let api_url = api_endpoint+'/home/get_penn_charts_config';
    return axios_post_call(api_url,{'test': 'test', 'start_date':start_date, 'end_date':end_date},token);
}

function push_penn_data(token, row_id, row_data, data)
{
    let api_url = api_endpoint+'/home/push_penn_data';
    return axios_post_call(api_url,{'test': 'test', 'row_id':row_id, 'row_data':row_data, 'data':data},token);
}

function get_export_charts_config(token, payload){
    let api_url = api_endpoint+'/home/get_export_charts_config';
    return axios_post_call(api_url, payload ,token);
}

function get_homepage_user_preferences(token){
    let api_url = api_endpoint+'/home/get_home_user_preferences';
    return axios_post_call(api_url, {} ,token);
}

function update_homepage_user_preferences(token, payload){
    let api_url = api_endpoint+'/home/update_home_user_preferences';
    return axios_post_call(api_url, payload ,token);
}

/*
Base Caller functions
*/
function axios_post_call(api_url,data,token){
    return new Promise(function(resolve,reject){
        axios.post(api_url, data, {headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`}}).then((res)=>{
            if(res.status == 200){
                resolve(res.data);
            }
            else{
                reject();
            }
        }).catch((e)=>{
            reject(api_url+e);
        })
    })
}

export default {
    get_home_charts_config,
    get_ticketing_charts_config,
    get_endpoint_protection_charts_config,
    get_device_monitoring_charts_config,
    get_email_monitoring_charts_config,
    get_penn_charts_config,
    push_penn_data,
    get_export_charts_config,
    get_homepage_user_preferences,
    update_homepage_user_preferences
}