<template>
  <v-container fluid class="px-8">
    <v-row v-if="export_overlay">
      <v-col cols="12" style="height: 100%; width: 100%">
        <v-overlay style="height: 100%; width: 100%" :absolute="true" :value="export_overlay" :z-index=999>
          <v-card class="pa-3" style="height: 100%; width: 100%; display:flex; background: grey">
              <v-progress-circular
                indeterminate
                size="100"
                color="secondary"
                style="margin: auto;"
              ></v-progress-circular>
          </v-card>
        </v-overlay>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col cols="12" style="display: flex">
        <v-row class="ma-0 pa-0" align="center">
          <v-col cols="auto">
            <span class="page-title" style="flex: 1">Export</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn outlined class="mx-2 pa-3 toolButton"  @click="exportOnClick" :disabled="export_overlay">
              Build Export
            </v-btn>
            <!-- <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-2 pa-3 toolButton" color="green">
                  Build Export
                </v-btn>
              </template>
              <v-list>
                <v-list-item link>
                  <v-list-item-title @click="downloadPDF()">Export as PDF</v-list-item-title>
                </v-list-item>
                <v-list-item link>
                  <v-list-item-title @click="downloadPPTX()">Export as PPTX</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="card_content" v-if="!chart_export_selected">
        <v-form v-model="validate_form">
          <v-row style="width: 100%" class="ma-0 pa-0">
            <v-expansion-panels focusable>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-row class="ma-0 pa-0">
                    <v-col cols="auto">
                      <v-icon v-if="!ticketing_all_stats" @click.stop="ticketing_all_stats = true; overall_ticketing_set(true);" class="ma-0 pa-0" color="secondary">mdi-checkbox-blank-outline</v-icon>
                      <v-icon v-if="ticketing_all_stats" @click.stop="ticketing_all_stats = false; overall_ticketing_set(false);" class="ma-0 pa-0" color="secondary">mdi-checkbox-marked-outline</v-icon>
                    </v-col>
                    <v-col cols="auto">
                      <span class="section-header">Ticketing</span>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="ma-0 pa-0">
                    <v-col cols="12">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="4">
                          <div class="column-title">Data Points</div>
                          <v-checkbox
                            v-model="ticketing_exports.data_point"
                            label="Open Tickets"
                            value="OpenTickets"
                            color="secondary"
                          >
                          </v-checkbox>
                          <v-checkbox
                            v-model="ticketing_exports.data_point"
                            label="High Priority Tickets"
                            value="HighPriorityTickets"
                            color="secondary"
                          >
                          </v-checkbox>
                        </v-col>
                        <v-col cols="4">
                          <div class="column-title">Graphs</div>
                          <v-checkbox
                            v-model="ticketing_exports.graphs"
                            label="Tickets By Priority"
                            value="TicketsByPriority"
                            color="secondary"
                          >
                          </v-checkbox>
                        </v-col>
                        <v-col cols="4">
                          <div class="column-title">Tables</div>
                          <v-checkbox
                            v-model="ticketing_exports.tables"
                            label="Open Tickets"
                            value="OpenTickets"
                            color="secondary"
                          >
                          </v-checkbox>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-row class="ma-0 pa-0">
                    <v-col cols="auto">
                      <v-icon v-if="!endpoint_protection_all_stats" @click.stop="endpoint_protection_all_stats = true; overall_endpoint_protection_set(true);" class="ma-0 pa-0" color="secondary">mdi-checkbox-blank-outline</v-icon>
                      <v-icon v-if="endpoint_protection_all_stats" @click.stop="endpoint_protection_all_stats = false; overall_endpoint_protection_set(false);" class="ma-0 pa-0" color="secondary">mdi-checkbox-marked-outline</v-icon>
                    </v-col>
                    <v-col cols="auto">
                      <span class="section-header">Endpoint Protection</span>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="ma-0 pa-0">
                    <v-col cols="12">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="4"> 

                        </v-col>
                        <v-col cols="4">
                          <div class="column-title">Graphs</div>
                          <v-checkbox
                            v-model="endpoint_protection_exports.graphs"
                            label="Threats By Type"
                            value="ThreatsByType"
                            color="secondary"
                          ></v-checkbox>
                          <v-checkbox
                            v-model="endpoint_protection_exports.graphs"
                            label="Threats By Detection Engine"
                            value="ThreatsByDetectionEngine"
                            color="secondary"
                          ></v-checkbox>
                          <v-checkbox
                            v-model="endpoint_protection_exports.graphs"
                            label="Threat Status"
                            value="ThreatStatus"
                            color="secondary"
                          ></v-checkbox>
                          <v-checkbox
                            v-model="endpoint_protection_exports.graphs"
                            label="Threat Analyst Verdict"
                            value="ThreatAnalystVerdict"
                            color="secondary"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="4">
                          <div class="column-title">Tables</div>
                          <v-checkbox
                            v-model="endpoint_protection_exports.tables"
                            label="Recent Security Events"
                            value="RecentSecurityEvents"
                            color="secondary"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-row class="ma-0 pa-0">
                    <v-col cols="auto">
                      <v-icon v-if="!device_monitoring_all_stats" @click.stop="device_monitoring_all_stats = true; overall_device_monitoring_set(true);" class="ma-0 pa-0" color="secondary">mdi-checkbox-blank-outline</v-icon>
                      <v-icon v-if="device_monitoring_all_stats" @click.stop="device_monitoring_all_stats = false; overall_device_monitoring_set(false);" class="ma-0 pa-0" color="secondary">mdi-checkbox-marked-outline</v-icon>
                    </v-col>
                    <v-col cols="auto">
                      <span class="section-header">Device Monitoring</span>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="ma-0 pa-0">
                    <v-col cols="12">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="4">
                          <div class="column-title">Data Points</div>
                          <v-checkbox
                            v-model="device_monitoring_exports.data_point"
                            label="Offline Devices"
                            value="OfflineDevices"
                            color="secondary"
                          ></v-checkbox>
                          <v-checkbox
                            v-model="device_monitoring_exports.data_point"
                            label="TotalDevices"
                            value="TotalDevices"
                            color="secondary"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="4">
                          <div class="column-title">Graphs</div>
                          <v-checkbox
                            v-model="device_monitoring_exports.graphs"
                            label="Antivirus Status"
                            value="AntivirusStatus"
                            color="secondary"
                          ></v-checkbox>
                          <v-checkbox
                            v-model="device_monitoring_exports.graphs"
                            label="Offline Devices By Type"
                            value="OfflineDevicesByType"
                            color="secondary"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="4"> 

                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-row class="ma-0 pa-0">
                    <v-col cols="auto">
                      <v-icon v-if="!email_monitoring_all_stats" @click.stop="email_monitoring_all_stats = true; overall_email_monitoring_set(true);" class="ma-0 pa-0" color="secondary">mdi-checkbox-blank-outline</v-icon>
                      <v-icon v-if="email_monitoring_all_stats" @click.stop="email_monitoring_all_stats = false; overall_email_monitoring_set(false);" class="ma-0 pa-0" color="secondary">mdi-checkbox-marked-outline</v-icon>
                    </v-col>
                    <v-col cols="auto">
                      <span class="section-header">Email Monitoring</span>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="ma-0 pa-0">
                    <v-col cols="12">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="4">
                          <div class="column-title">Data Points</div>
                          <v-checkbox
                            v-model="email_monitoring_exports.data_point"
                            label="Held Emails"
                            value="HeldEmails"
                            color="secondary"
                          ></v-checkbox>
                          <v-checkbox
                            v-model="email_monitoring_exports.data_point"
                            label="Rejected Emails"
                            value="RejectedEmails"
                            color="secondary"
                          ></v-checkbox>
                          <v-checkbox
                            v-model="email_monitoring_exports.data_point"
                            label="Bounced Emails"
                            value="BouncedEmails"
                            color="secondary"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="4">
                          <div class="column-title">Graphs</div>
                          <v-checkbox
                            v-model="email_monitoring_exports.graphs"
                            label="Email Activity In Past Week"
                            value="EmailActivityInPastWeek"
                            color="secondary"
                          ></v-checkbox>
                          <v-checkbox
                            v-model="email_monitoring_exports.graphs"
                            label="Email Traffic"
                            value="EmailTraffic"
                            color="secondary"
                          ></v-checkbox>
                          <v-checkbox
                            v-model="email_monitoring_exports.graphs"
                            label="Rejections By Type"
                            value="RejectionsByType"
                            color="secondary"
                          ></v-checkbox>
                        </v-col> 
                        <v-col cols="4"> </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="12" v-else class="export_preview_wrapper" >
        <v-row class="ma-0 pa-0" v-if="charts_config">
          <v-col cols="12" v-for="s in Object.keys(charts_config)" :key="s">
            
            <v-col cols="12" class="section-header">{{ charts_config[s]['section_header'] }}</v-col>
            <v-col cols="9" v-if="charts_config[s]['data']" class="export_element">
              <v-col v-for="k in Object.keys(charts_config[s]['data'])" :key="k">
                <MainPageStat
                    :statistic_title_name="charts_config[s]['data'][k]['stat_title']"
                    :value="charts_config[s]['data'][k]['stat_value']"
                >
                </MainPageStat>
              </v-col>
            </v-col>
            <v-col cols="9" v-if="charts_config[s]['charts']" class="export_element">
              <v-col
                  v-for="k in Object.keys(charts_config[s]['charts'])"
                  :key="charts_config[s]['charts'][k]['chart_title']"
              >
                <BaseChart
                    style="width:800px"
                    :statistic_title_name="charts_config[s]['charts'][k]['chart_title']"
                    :link="charts_config[s]['charts'][k]['chart_link']"
                    :value="charts_config[s]['charts'][k]['chart_value']"
                    :chart_type="charts_config[s]['charts'][k]['chart_type']"
                    :chart_id="s+'-'+k"
                >
                </BaseChart>
              </v-col>
            </v-col>
            <v-col cols="8" v-if="charts_config[s]['tables']" class="export_element" >
              <v-col v-for="k in Object.keys(charts_config[s]['tables'])" :key="k">
                <StatisticsTable
                    :statistic_title_name="charts_config[s]['tables'][k]['title']"
                    :value="charts_config[s]['tables'][k]['content']"
                ></StatisticsTable>
              </v-col>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseChart from "../StatisticComponents/Charts/BaseChart.vue";
import StatisticsTable from "../StatisticComponents/StatisticsTable.vue";
import MainPageStat from "../StatisticComponents/MainPageStat.vue";
import api_caller from "@/javascript/chart_config_retrieval.js";
import pdfBuilder from "@/javascript/pdfBuilder_format2";
import PPTXBuilder from "@/javascript/pptxBuilder_format2";
import * as echarts from 'echarts';

const DEFAULT_TICKET_EXPORTS = {
  data_point: ['OpenTickets', 'HighPriorityTickets'],
  graphs: ['TicketsByPriority', 'TicketsByStatus'],
  tables: ['OpenTickets']
};

const DEFAULT_EMAIL_PROECTION_EXPORT = {
  data_point: [],
  graphs: ['ThreatsByType', 'ThreatsByDetectionEngine', "ThreatsByStatus", "ThreatsByAnalystVerdict"],
  tables: ['RecentSecurityEvents']
};

const DEFAULT_DEVICE_MONITORING_EXPORT = {
  data_point: ['OfflineDevices', 'TotalDevices'],
  graphs: ['AntivirusStatus', 'OfflineDevicesByType'],
  tables: []
};

const DEFAULT_EMAIL_MONITORTING_EXPORT = {
  data_point: ['HeldEmails', 'RejectedEmails', 'BouncedEmails'],
  graphs: ['EmailActivityInPastWeek', 'EmailTraffic', 'RejectionsByType'],
  tables: []
};

export default {
  name: "ExportPage",
  props: [
    'start_date',
    'end_date'
  ],
  data: () => ({
    ticketing_raw_data: null,
    open_tickets_lst: [],
    old_tickets: [],
    open_tickets: null,
    high_priority_open_tickets: null,
    open_tickets_by_priority: [],
    charts_config: null,
    chart_export_selected: false,
    export_overlay: false,

    ticketing_exports: {... DEFAULT_TICKET_EXPORTS},
    endpoint_protection_exports: {... DEFAULT_EMAIL_PROECTION_EXPORT},
    device_monitoring_exports: {... DEFAULT_DEVICE_MONITORING_EXPORT},
    email_monitoring_exports: {... DEFAULT_EMAIL_MONITORTING_EXPORT},

    ticketing_all_stats: true,
    email_monitoring_all_stats: true,
    device_monitoring_all_stats: true,
    endpoint_protection_all_stats: true,

    formValidity: false,
  }),
  components: {
    BaseChart,
    StatisticsTable,
    MainPageStat,
  },
  computed: {
    validate_form: function() {
      if (
        (
          this.ticketing_exports.data_point.length == 0 &&
          this.ticketing_exports.graphs.length == 0 &&
          this.ticketing_exports.tables.length == 0 
        ) &&
        (
          this.endpoint_protection_exports.data_point.length == 0 &&
          this.endpoint_protection_exports.graphs.length == 0 &&
          this.endpoint_protection_exports.tables.length == 0 
        ) &&
        (
          this.device_monitoring_exports.data_point.length == 0 &&
          this.device_monitoring_exports.graphs.length == 0 &&
          this.device_monitoring_exports.tables.length == 0 
        ) &&
        (
          this.email_monitoring_exports.data_point.length == 0 &&
          this.email_monitoring_exports.graphs.length == 0 &&
          this.email_monitoring_exports.tables.length == 0 
        )
      ) {
        // this.formValidity = false;
        return false;
      } else {
        // this.formValidity = true;
        return true;
      }
    }
  },
  methods: {
    overall_ticketing_set: function(set_val){
      if(set_val){
        this.ticketing_exports = {... DEFAULT_TICKET_EXPORTS};
      }
      else{
        this.ticketing_exports.data_point = [];
        this.ticketing_exports.graphs = [];
        this.ticketing_exports.tables = [];
      }
    },
    overall_endpoint_protection_set: function(set_val){
      if(set_val){
        this.endpoint_protection_exports = {... DEFAULT_EMAIL_PROECTION_EXPORT};
      }
      else{
        this.endpoint_protection_exports.data_point = [];
        this.endpoint_protection_exports.graphs = [];
        this.endpoint_protection_exports.tables = [];
      }
    },
    overall_device_monitoring_set: function(set_val){
      if(set_val){
        this.device_monitoring_exports = {... DEFAULT_DEVICE_MONITORING_EXPORT};
      }
      else{
        this.device_monitoring_exports.data_point = [];
        this.device_monitoring_exports.graphs = [];
        this.device_monitoring_exports.tables = [];
      }
    },
    overall_email_monitoring_set: function(set_val){
      if(set_val){
        this.email_monitoring_exports = {... DEFAULT_EMAIL_MONITORTING_EXPORT};
      }
      else{
        this.email_monitoring_exports.data_point = [];
        this.email_monitoring_exports.graphs = [];
        this.email_monitoring_exports.tables = [];
      }
    },

    downloadPDF: function () {
      this.pdf_doc = new pdfBuilder();
      let width = 200;
      let height = 100;

      // add data_point elements
      if (this.charts_config["data"]) {
        for (let data_point in this.charts_config["data"]) {
          this.pdf_doc.add_pdf_element(
            data_point,
            this.charts_config["data"][data_point],
            "data"
          );
        }
      }

      // add graph elements
      for (let ref in this.$refs) {
        if (this.$refs[ref][0]) {
          let chart = this.$refs[ref][0].$children[0].$children[1].chartObject;
          let chartImage = chart.getImageURI();

          let data = { image: chartImage, width: width, height: height };

          this.pdf_doc.add_pdf_element(ref, data, "graph");
        }
      }

      // add table elements:
      if (this.charts_config["tables"]) {
        for (let table in this.charts_config["tables"]) {
          this.pdf_doc.add_pdf_element(
            table,
            this.charts_config["tables"][table],
            "table"
          );
        }
      }

      this.pdf_doc.build();
      this.pdf_doc.save();
    },

    downloadPPTX: function () {
      this.pptx_doc = new PPTXBuilder();
      for(let section in this.charts_config) {
        // add table elements:
        if (this.charts_config[section]["tables"]) {
          for (let table in this.charts_config[section]["tables"]) {
            this.pptx_doc.add_element(table, this.charts_config[section]["tables"][table], section, "table");
          }
        }

        if(this.charts_config[section]["charts"]) {

          let matched_point_elements = Object.keys(this.charts_config[section]["matched"])

          // add charts elements to export
          for (let chart in this.charts_config[section]["charts"]) {
            let elementId = section + '-' + chart
            const chartElement = echarts.getInstanceByDom(document.getElementById(elementId));
            const dataURL = chartElement.getDataURL({
              type: 'svg', // You can change the image type here
              pixelRatio: 5, // Adjust the pixel ratio for higher resolution
            });

            let imageData = {
              image: dataURL,
              title: this.charts_config[section]["charts"][chart]["chart_title"],
            };

            this.pptx_doc.add_element(chart, imageData, section, "graph");

            // add data points elements to export
            if(matched_point_elements.includes(chart)) {
              for (let data_point in this.charts_config[section]["matched"][chart]) {
                this.pptx_doc.add_data_point_element(data_point, this.charts_config[section]["matched"][chart][data_point], section, chart)
              }
            }
          }
        }
      }

      this.pptx_doc.build();
      this.pptx_doc.save();
      this.$emit('export_built')
    },

    getChartConfig: async function () {
      this.chart_export_selected = true;

      let payload = {
        date_range: {
          start_date: this.start_date,
          end_date: this.end_date
        },
        ticketing: {
          data: this.ticketing_exports.data_point,
          graphs: this.ticketing_exports.graphs,
          tables: this.ticketing_exports.tables
        },
        endpoint_protection: {
          data: this.endpoint_protection_exports.data_point,
          graphs: this.endpoint_protection_exports.graphs,
          tables: this.endpoint_protection_exports.tables
        },
        device_monitoring: {
          data: this.device_monitoring_exports.data_point,
          graphs: this.device_monitoring_exports.graphs,
          tables: this.device_monitoring_exports.tables
        },
        email_monitoring: {
          data: this.email_monitoring_exports.data_point,
          graphs: this.email_monitoring_exports.graphs,
          tables: this.email_monitoring_exports.tables
        }
      };

      const token = await this.$auth.getTokenSilently();
      let charts_config = await api_caller
        .get_export_charts_config(token, payload)
        .then((resp) => {
          if (resp["success"]) {
            return resp["data"];
          } else {
            return false;
          }
        })
        .catch((e) => {
          console.log(e);
          return false;
        });

      if (charts_config) {
        this.charts_config = charts_config;
      } else {
        //this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
      }
    },

    exportOnClick: async function () {
      this.export_overlay = true;
      let that = this;
      this.getChartConfig().then(
        function() {
          setTimeout(() => {
            that.downloadPPTX();
            that.resetExport();
            that.export_overlay = false;
          }, 5000);
          
        }
      );
    },

    resetExport: function () {
      this.chart_export_selected = false;

      this.ticketing_exports = {... DEFAULT_TICKET_EXPORTS},
      this.endpoint_protection_exports = {... DEFAULT_EMAIL_PROECTION_EXPORT},
      this.device_monitoring_exports = {... DEFAULT_DEVICE_MONITORING_EXPORT},
      this.email_monitoring_exports = {... DEFAULT_EMAIL_MONITORTING_EXPORT},
      this.$refs = {};
    },
  },
};
</script>

<style>
.toolButton {
  font-family: "Gothic Bold" !important;
  /* font-size: 15pt !important; */
  color: #66b85a !important;
} 

.page-title {
  font-family: "Gothic Bold" !important;
  font-size: 25pt !important;
}

.section-header {
  font-family: "Gothic Bold" !important;
  font-size: 16pt;
  padding-bottom: 10px;
}

.column-title {
  font-family: "Gothic Bold" !important;
  font-size: 10pt;
}

.page-link {
  font-family: "Gothic" !important;
  font-size: 15pt !important;
  margin-left: 10px;
  color: #66b85a;
}

.card_content {
  height: 100%;
}

.export_button {
  width: 100%;
}

.export_element {
  margin: auto 0;
}

.export_preview_wrapper {
  position: absolute;
  height:100%;
  overflow:hidden;
  margin-top: 30px;
}

.v-overlay__content {
  height: 100%;
  width: 100%;
}
</style>
